import React from 'react';
import classNames from 'classnames';

import { CommonComponent } from '../../../../types';

export interface OnboardingButtonsProps extends CommonComponent {
  nextEnabled: boolean,
  backEnabled: boolean,
  completable: boolean,
  completionLink: string,
  nextOnclick: () => void,
  backOnClick: () => void,
}

export const OnboardingButtons = ({
  nextEnabled,
  backEnabled,
  completable,
  completionLink,
  nextOnclick,
  backOnClick,
}: OnboardingButtonsProps) => (
  <div
    className={classNames(
      'jobox__onboarding-buttons-container',
      {
        'jobox__onboarding-buttons-container--justify-space': backEnabled,
        'jobox__onboarding-buttons-container--justify-center': !backEnabled,
      },
    )}
  >
    {backEnabled && (
      <button
        type="button"
        className={classNames(
          'jobox__onboarding-buttons__back-button',
          { 'jobox__onboarding-buttons__full-button': (!nextEnabled && !completable) },
        )}
        onClick={backOnClick}
      >
        BACK
      </button>
    )}
    {nextEnabled && !completable && (
      <button
        type="button"
        className={classNames(
          'jobox__onboarding-buttons__next-button',
          { 'jobox__onboarding-buttons__full-button': !backOnClick },
        )}
        onClick={nextOnclick}
      >
        NEXT
      </button>
    )}
    {completable && (
      <a href={completionLink}>
        <button
          type="button"
          className={classNames(
            'jobox__onboarding-buttons__next-button',
            { 'jobox__onboarding-buttons__full-button': !backEnabled },
          )}
        >
          LOOKS GOOD!
        </button>
      </a>
    )}
  </div>
);
