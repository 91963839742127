import React from 'react';

import { CommonComponent } from '../../../../types';

import ToggleOnIcon from './toggle-dark-on.svg';
import ToggleOffIcon from './toggle-dark-off.svg';

export interface ToggleItemCompactProps extends CommonComponent {
  title: string,
  enabled: boolean,
  showToggleButton: boolean,
  onToggle?: () => void,
  children?: React.ReactNode,
}

export const ToggleItemCompact = ({
  title,
  enabled,
  onToggle,
  showToggleButton,
  children,
}: ToggleItemCompactProps) => (
  <div className="jobox__toggle-item-compact">
    <div className="jobox__toggle-item-compact__left-section">
      <div className="jobox__toggle-item-compact__left-section__title">
        <span>{title}</span>
      </div>
    </div>
    <div className="jobox__toggle-item-compact__right-section">
      {children}
      {showToggleButton && (
        <button
          type="button"
          className="jobox__toggle-item-compact__right-section__btn"
          onClick={onToggle}
        >
          <img src={enabled ? ToggleOnIcon : ToggleOffIcon} alt={enabled ? 'on' : 'off'} />
        </button>
      )}
    </div>
  </div>
);
