import React from 'react';
import classNames from 'classnames';

import { CommonComponent } from '../../../../types';

import DashIcon from './dashIcon.svg';

export interface YearRange {
  yearStart: number,
  yearEnd: number,
}

export interface YearRangePickerProps extends CommonComponent {
  minYear: number,
  enabled: boolean,
  currentYearStart: string | number,
  currentYearEnd: string | number,
  onSetYearRange: (range: YearRange) => void,
}

export const YearRangePicker = ({
  minYear,
  enabled,
  currentYearStart,
  currentYearEnd,
  onSetYearRange,
}: YearRangePickerProps) => {
  const yearNow = new Date().getFullYear();
  const years = Array.from({ length: yearNow - minYear + 1 }, (_, i) => yearNow - i);

  const onSetYearStart = (e) => {
    const yearStart = e.target.value ? parseInt(String(e.target.value), 10) : null;
    const yearEnd = currentYearEnd ? parseInt(String(currentYearEnd), 10) : null;
    onSetYearRange({
      yearStart,
      yearEnd: yearEnd >= yearStart ? yearEnd : null,
    });
  };

  const onSetYearEnd = (e) => {
    const yearStart = currentYearStart ? parseInt(String(currentYearStart), 10) : null;
    const yearEnd = e.target.value ? parseInt(String(e.target.value), 10) : null;
    onSetYearRange({
      yearStart: yearStart <= yearEnd ? yearStart : null,
      yearEnd,
    });
  };

  return (
    <div className={classNames({ 'jobox__year-range-picker': true, 'jobox__year-range-picker--active': enabled })}>
      <div className="jobox__year-range-picker__select-container-left">
        <select
          value={currentYearStart || ''}
          className="jobox__year-range-picker__select-box"
          onChange={onSetYearStart}
          disabled={!enabled}
        >
          <option value="">All years</option>
          {years.map((year) => (
            <option key={year}>{year}</option>
          ))}
        </select>
      </div>
      <img
        className="jobox__year-range-picker__icon-dash"
        src={DashIcon}
        alt="to"
      />
      <div className="jobox__year-range-picker__select-container-right">
        <select
          value={currentYearEnd || ''}
          className="jobox__year-range-picker__select-box"
          onChange={onSetYearEnd}
          disabled={!enabled}
        >
          <option value="">Present</option>
          {years.map((year) => (
            <option key={year}>{year}</option>
          ))}
        </select>
      </div>
    </div>
  );
};
